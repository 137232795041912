.App {
    text-align: center;
    font-family: "Impact", Impact, Haettenschweiler, "Arial Narrow Bold",
        sans-serif;
    color: white;
    background-color: black;
}

.navbar {
    justify-content: center;
    font-size: clamp(5px, 4vw, 30px);
    padding-top: 0;
    padding-bottom: 0;
}

.navbar .navbar-nav {
    display: flex;
    flex-direction: row !important;
}

.nav-link {
    padding-right: 2vw;
    padding-left: 2vw;
}

.banner {
    width: 100%;
}

.banner-image {
    width: 100%;
    object-fit: cover;
}

body, html {
    background-color: black;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
}

.button-box {
    display: flex;
    flex-direction: row;
    /* Line them up side by side */
    gap: 10px;
    /* Adds space between the buttons */
    justify-content: center;
    /* Center buttons horizontally */
    padding: 20px;
}

.action-button,
.action-button a {
    flex: 1;
    /* Makes the buttons share available space equally */
    display: flex;
    /* This ensures the flex properties apply to children */
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    text-decoration: none;
    /* Removes underline from links */
    cursor: pointer;
    /* Shows a pointer cursor on hover */
    /*padding: 10px 15px; !* Adjust padding for better sizing *!*/
}

/* Media query for screens smaller than 768px wide */
@media (max-width: 768px) {
    .button-box {
        flex-direction: column;
        align-items: stretch; /* This will make buttons full width */
        /*gap: 15px; !* Increase gap for better separation on mobile *!*/
    }

    .action-button,
    .action-button a {
        width: 100%; /* Full width on mobile */
        max-width: 300px; /* Maximum width to prevent overly wide buttons */
        margin: 0 auto; /* Center the buttons */
        /*padding: 12px 10px; !* Slightly more vertical padding for touch targets *!*/
    }
}
.list-container {
    display: flex;
    justify-content: center;
    margin-left: 15px;
    font-size: clamp(20px, 4vw, 35px);
}

.t-list {
    list-style: none;
    padding-left: 0;
    text-align: left;
}

.t-list li {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.bullet {
    margin-right: 10px;
    width: 4vw;
    height: auto;
}

.video-container {
    font-size: clamp(6px, 1vw, 20px);
    position: relative;
    text-align: center;
    color: white;
}

.iframe-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.social-image {
    padding-top: 8px;
}

html {
    scroll-padding-top: 75px;
}

.section-title {
    font-size: clamp(24px, 6vw, 80px);
}

.section-title img {
    width: 12vw;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 3%;
}

.bar {
    padding-top: 70px;
    padding-bottom: 70px;
}

.copy-button {
    margin-left: 10px;
    font-size: 14px;
    border-radius: 5px;
    background-color: red;
    border: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header-container {
    width: 100%;
    overflow: hidden;
}

.header-background {
    background-image: url('img/comic-header.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    padding-top: 56.25%; /* Adjust based on your image aspect ratio */
    padding-bottom: 20px;
    position: relative;
}

.header-image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: auto;
}

.embed-responsive {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.embed-responsive-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.align-items-center {
    min-height: 100%;
}

.video-wrapper {
    width: 100%;
}

@media (min-width: 768px) {
    .video-wrapper {
        width: 90%; /* Adjust this value as needed */
    }
}

.row:nth-child(even) {
    flex-direction: row-reverse;
}

/* Mobile layout */
@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .row:nth-child(even) {
        flex-direction: column;
    }

    .row:nth-child(even) .video-container {
        order: -1;
    }
}